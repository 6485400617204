import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { ToastsStore } from 'react-toasts';
import i18n, { LANG } from './../i18n';
import { isValidIban } from './validate';
import { PWD_CONSTRAINTS, PATTERN_SPECIAL_CHARACTER } from './../constants/constants';


export function getUrlParams() {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('t');
  const partnerId = searchParams.get('p');
  let reqData = searchParams.get('data');
  
  if(!!reqData){
    reqData = window.atob(reqData);
    reqData = JSON.parse(reqData);
  } else {
    reqData = {};
  }
  
  return { token, partnerId, reqData };
}

export function getErrorMessage(errorCode = 0) {
  return 'err' + errorCode;
}

export function showApiError(error) {
  if(error && error.response){
    if(error.response.data && error.response.data.message){
      setErrorToast(error.response.data.code, error.response.data.message);
    } else {
      setErrorToast(0, i18n.t('unexpectedError'));
    }
  } else {
    setErrorToast(0, i18n.t('unexpectedError'));
  }
}

export function setErrorToast(errorCode, errorMessage) {
  return ToastsStore.error(<span>{`${errorCode}: ${errorMessage}`}</span>);
}

export function getDateForTransfer(date) {
  let day = date.getDate();
  day = day < 10 ? '0' + day : day;
  let month = date.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  let year = date.getFullYear();
  
  return year + '-' + month + '-' + day;
}

export function getDateWithOffset(date, dayOffset) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + dayOffset);
}

export function convertPriceForDisplay(price, useDash = false) {
  let language = LANG ? LANG : navigator.language.split("-")[0];
  let decimalSeparator = language === 'de' ? ',' : '.';
  let precision = 2;
  let currencySymbol = '€';
  let prefix = '';
  let suffix = '';
  
  if(!price){
    price = 0;
  }
  
  
  if(useDash === true && parseInt(price * 100) % 100 === 0) {
    precision = 0;
    suffix = decimalSeparator + '– ';
  }
  
  suffix += currencySymbol;
  
  price = parseFloat(price);
  
  return (
    <CurrencyFormat value={price} displayType={'text'} decimalScale={precision} fixedDecimalScale={true} decimalSeparator={decimalSeparator} prefix={prefix} suffix={suffix}/>
  )
}

export function splitAddress(addressString) {
  let addressObject = {
    status: false,
    errorCode: 0,
    street: undefined,
    postalCode: undefined,
    city: undefined,
  };
  
  if(addressString && typeof addressString === 'string') {
    let addressArray = addressString.split(",");
    if(addressArray.length > 1){
      addressObject.street = addressArray[0].trim();
      let address2Trimmed = addressArray[1].trim();
      addressArray = address2Trimmed.split(" ");
      if(addressArray.length > 1){
        addressObject.postalCode = addressArray[0].trim();
        addressObject.city = (addressArray.splice(1, addressArray.length)).join(" ").trim();
        addressObject.status = true;
      }else{
        addressObject.errorCode = 10217;
      }
    }else{
      addressObject.errorCode = 10216;
    }
  }else{
    addressObject.errorCode = 10215;
  }
  
  return addressObject;
}

export const getSplittedAddress = (addressComponents) => {
  let companyObject = {
    street: '',
    housenumber: '',
    zip: '',
    city: '',
    country: '',
  };
  
  for(let i = 0; i < (addressComponents || []).length; i++) {
    let addressComponent = addressComponents[i];
    
    switch(addressComponent.types[0]) {
      case 'street_number' :
        companyObject.housenumber = addressComponent.long_name;
      break;
      case 'route' :
        companyObject.street = addressComponent.long_name;
      break;
      case 'locality' :
        companyObject.city = addressComponent.long_name;
      break;
      case 'country' :
        // companyObject.country = addressComponent.long_name;
        companyObject.country = addressComponent.short_name;
      break;
      case 'postal_code' :
        companyObject.zip = addressComponent.long_name;
      break;
      default:
      break;
    }
  };
  
  return companyObject;
}

export const checkIbanInput = (event) => {
  let element = event.target;
  if(element) {
    let iban = element.value;
    if(iban && iban.length > 0) {
      if(isValidIban(iban)){
        element.parentElement.classList.add('input-checked');
      }
      else {
        element.parentElement.classList.remove('input-checked');
      }
    }
  }
}

export function checkPasswordConstraint(passwordConstraintKey, string) {
  let returnVal = false;
  
  if(string) {
    switch(passwordConstraintKey) {
      case 'all':
        returnVal = (!PWD_CONSTRAINTS.stringLength || string.length >= PWD_CONSTRAINTS.stringLength) &&
        (!PWD_CONSTRAINTS.amountLowercase || countLowercase(string) >= PWD_CONSTRAINTS.amountLowercase) &&
        (!PWD_CONSTRAINTS.amountUppercase || countUppercase(string) >= PWD_CONSTRAINTS.amountUppercase) &&
        (!PWD_CONSTRAINTS.amountNumbers || countNumbers(string) >= PWD_CONSTRAINTS.amountNumbers) &&
        (!PWD_CONSTRAINTS.amountSpecialCharacters || countSpecialCharacters(string) >= PWD_CONSTRAINTS.amountSpecialCharacters) &&
        getUnknownCharacters(string) === '';
      break;
      
      case 'stringLength':
        returnVal = string.length >= PWD_CONSTRAINTS.stringLength;
      break;
      
      case 'amountLowercase':
        returnVal = countLowercase(string) >= PWD_CONSTRAINTS.amountLowercase;
      break;
      
      case 'amountUppercase':
        returnVal = countUppercase(string) >= PWD_CONSTRAINTS.amountUppercase;
      break;
      
      case 'amountNumbers':
        returnVal = countNumbers(string) >= PWD_CONSTRAINTS.amountNumbers;
      break;
      
      case 'amountSpecialCharacters':
        returnVal = countSpecialCharacters(string) >= PWD_CONSTRAINTS.amountSpecialCharacters;
      break;
      
      default: 
        returnVal = false;
    }
  }
  
  return returnVal;
}

export function getUnknownCharacters(string) {
  let unknownCharacters = [];
  
  if(string && string.length > 0) {
    let cleanString = string;
    cleanString = cleanString.replace(/[a-z]/g, ""); // remove all lowercase letters
    cleanString = cleanString.replace(/[A-Z]/g, ""); // remove all uppercase letters
    cleanString = cleanString.replace(/\d/g, ""); // remove all numbers
    cleanString = cleanString.replace(PATTERN_SPECIAL_CHARACTER, ""); // remove all predefined special characters
    
    
    let lengthBefore = cleanString.length;
    cleanString = cleanString.split(' ').join(''); // remove all whitespaces
    let lengthAfter = cleanString.length;
    if(lengthAfter < lengthBefore && !unknownCharacters.includes('space')) {
      unknownCharacters.push('space');
    }
    
    for (let i = 0; i < cleanString.length; i++) {
      if(!unknownCharacters.includes(cleanString.charAt(i))) {
        unknownCharacters.push(cleanString.charAt(i));
      }
    }
  }
  
  return unknownCharacters.join(' ');
}

function countLowercase(string) {
  let lowercase = string.match(/[a-z]/g);
  return lowercase ? lowercase.length : 0;
}

function countUppercase(string) {
  let uppercase = string.match(/[A-Z]/g);
  return uppercase ? uppercase.length : 0;
}

function countNumbers(string) {
  let numbers = string.match(/\d/g);
  return numbers ? numbers.length : 0;
}

function countSpecialCharacters(string) {
  let specialCharacters = string.match(PATTERN_SPECIAL_CHARACTER);
  return specialCharacters ? specialCharacters.length : 0;
}