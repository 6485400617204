import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import translationDE from './locales/de/translation.json';
import translationEN from './locales/en/translation.json';


const resources = {
  de: {
    translation: translationDE
  },
  en: {
    translation: translationEN
  }
};

let browserLang = navigator.language.split("-")[0];
export const LANG = browserLang === 'en' ? 'en' : 'de'; // TODO: add locales for other languages
// export const LANG = navigator.language.split("-")[0];

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: LANG,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;