import { splitAddress, checkPasswordConstraint } from './utils';
import { PATTERN_EMAIL, PWD_CONSTRAINTS } from './../constants/constants';

const validate = elements => {
  const validationPrefix = 'validation.';
  let errors = [];
  let errorKey;
  
  if(elements){
    elements.map(elem => {
      let variables = {};
      
      // field required
      if(elem.type !== 'checkbox' && elem.required && elem.defaultValue === ''){
        errorKey = validationPrefix + 'required';
        variables = Object.assign(elem.name);
      }
      
      // email should match reg exp
      else if(elem.type === 'email' && elem.value.length > 0 && !PATTERN_EMAIL.test(elem.defaultValue)){
        errorKey = validationPrefix + 'emailPatternMismatch';
      }
      
      // password should match reg exp
      else if(elem.name === 'password' && !checkPasswordConstraint('all', elem.defaultValue)){
        errorKey = validationPrefix + 'passwordPatternMismatch';
        variables = Object.assign(variables, PWD_CONSTRAINTS);
      }
      
      // field should have same value as another field
      else if(elem.name === 'confirmPassword' && elem.defaultValue !== document.getElementById('password').defaultValue){
        errorKey = validationPrefix + 'passwordNotConfirmedCorrectly';
      }
      
      // field should fulfill iban constraints
      else if(elem.name === 'iban' && elem.value.length > 0 && !isValidIban(elem.defaultValue)){
        errorKey = validationPrefix + 'ibanInvalid';
      }
      
      // checkbox should be selected
      else if(elem.type === 'checkbox' && elem.required && !elem.checked){
        errorKey = validationPrefix + 'notChecked';
        variables = Object.assign(elem.name);
      }
      
      // field should be greater than min value 
      // else if(inputConfig.min && elem.value < inputConfig.min){
      //   errorKey = 'lowerThanMin';
      //   variables.min = inputConfig.min;
      // }
      
      // field should be lower than max value 
      // else if(inputConfig.max && elem.value < inputConfig.max){
      //   errorKey = 'greaterThanMax';
      //   variables.max = inputConfig.max;
      // }
      
      // field should be formatted as address
      else if(elem.name === 'address'){
        let splittedAddress = splitAddress(elem.defaultValue);
      
        if(!splittedAddress.status) {
          errorKey = 'err' + splittedAddress.errorCode;
        }
      }
      
      if(!!errorKey){
        errors.push({
          element: elem,
          errorKey: errorKey,
          variables 
        });
      }
      
      return true;
    });
  }
  
  return errors;
}

// Iban validation /////////////////////////////////////////////////////////////

const CODE_LENGTHS = {
    AL: 28,
    AD: 24,
    AT: 20,
    AZ: 28,
    BH: 22,
    BE: 16,
    BA: 20,
    BR: 29,
    BG: 22,
    CR: 21,
    HR: 21,
    CY: 28,
    CZ: 24,
    DK: 18,
    DO: 28,
    TL: 23,
    EE: 20,
    FO: 18,
    FI: 18,
    FR: 27,
    GE: 22,
    DE: 22,
    GI: 23,
    GR: 27,
    GL: 18,
    GT: 28,
    HU: 28,
    IS: 26,
    IE: 22,
    IL: 23,
    IT: 27,
    JO: 30,
    KZ: 20,
    XK: 20,
    KW: 30,
    LV: 21,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    MK: 19,
    MT: 31,
    MR: 27,
    MU: 30,
    MC: 27,
    MD: 24,
    ME: 22,
    NL: 18,
    NO: 15,
    PK: 24,
    PS: 29,
    PL: 28,
    PT: 25,
    QA: 29,
    RO: 24,
    SM: 27,
    SA: 24,
    RS: 22,
    SK: 24,
    SI: 19,
    ES: 24,
    SE: 24,
    CH: 21,
    TN: 24,
    TR: 26,
    AE: 23,
    GB: 22,
    VG: 24,
    DZ: 24,
    AO: 25,
    BJ: 28,
    BF: 27,
    BI: 16,
    CM: 27,
    CV: 25,
    IR: 26,
    CI: 28,
    MG: 27,
    ML: 28,
    MZ: 25,
    SN: 28,
    UA: 29,
};

function mod97(string) {
    let checksum = string.slice(0, 2);
    let fragment;
    
    for (let offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum === 1;
}

export function isValidIban(value) {
    
    let iban = String(value).toUpperCase().replace(/[^A-Z0-9]/g, '');
    let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    let digits;
    
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    
    return mod97(digits);
}

export default validate;