export const PRODUCT_NAME = 'Phoenix';

export const DUMMY_HREF = '#';

export const CLS_FILLED = 'filled';

export const CLS_DISABLED = 'disabled';

export const CLS_VISIBLE = 'visible';

export const CLS_HIDDEN = 'hidden';

export const PATTERN_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const PATTERN_SPECIAL_CHARACTER = /[!@#$%^&*(),.?":{}|<>]/g; // allowed characters

export const PWD_CONSTRAINTS = {
  // amountLowercase: 1, // Not required in Phoenix
  amountUppercase: 1,
  amountNumbers: 1,
  amountSpecialCharacters: 1,
  stringLength: 8,
}

export const OPTIONS_IBAN = {
  blocks: [4,4,4,4,4,4,4,4,4],
  delimiter: ' ',
  uppercase: true
};

export const TYPES_INFO_MODAL = {
  IMPRINT: 'imprint',
  PRIVACY: 'privacy',
  TERMS_OF_USE: 'generalConditions',
  EULA: 'eula',
  SEPA: 'sepa',
};

export const DEFAULT_VALUES = {
  BRANCH_ID: 7,
  SUBSCRIPTION_ID: 2,
  BILLING_PERIOD: 52,
}

export const BRANCHES = {
  branch7: {
    branchId: 7,
    identifier: "hospitality",
  },
  branch8: {
    branchId: 8,
    identifier: "retail",
  }
}

export const ALL_BRANCHES = {
  branch1: {
    branchId: 1,
    identifier: "beauty",
  },
  branch2: {
    branchId: 2,
    identifier: "boutique",
  },
  branch3: {
    branchId: 3,
    identifier: "friseur",
  },
  branch4: {
    branchId: 4,
    identifier: "direktverkauf",
  },
  branch5: {
    branchId: 5,
    identifier: "baeckerei",
  },
  branch6: {
    branchId: 6,
    identifier: "kiosk",
  },
  branch7: {
    branchId: 7,
    identifier: "hospitality",
  },
  branch8: {
    branchId: 8,
    identifier: "retail",
  },
  branch9: {
    branchId: 9,
    identifier: "none",
  },
  branch10: {
    branchId: 10,
    identifier: "hospitalityNoDemoData",
  },
  branch11: {
    branchId: 11,
    identifier: "retailNoDemoData",
  }
}

export const SUBSCRIPTION_MODELS = {
  subscription1: {
    subscriptionId: 1,
    price: 4,
    price2: 7,
    users: 3,
    workstations: 1,
    // pricePerUser: 1,
    // featureList: [2,3,4],
    details: [1,2,3,4,5,6],
    disable: false,
  },
  subscription2: {
    subscriptionId: 2,
    price: 8,
    price2: 12,
    users: 5,
    workstations: 3,
    // pricePerUser: 1,
    // featureList: [2,3,4],
    details: [1,2,3,4,5,6,7,8,9,10,11],
    disable: false,
  },
  subscription3: {
    subscriptionId: 3,
    price: 49,
    price2: undefined,
    users: 20,
    workstations: 5,
    // pricePerUser: 1,
    // featureList: [2,3,4],
    details: [1,2,3,4,5,6,7,8,9,10,11,12],
    disable: true,
  },
}

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCsTCcgEkYCeMev0v32U8WCcn8rmGjGy_M',
  authDomain: 'phoenix-live-2b119.firebaseapp.com',
  databaseURL: 'https://phoenix-live-2b119.firebaseio.com',
  projectId: 'phoenix-live-2b119',
  storageBucket: 'phoenix-live-2b119.appspot.com',
  messagingSenderId: '317530039348',
  appId: '1:317530039348:web:f6b32b74cef44cec5a5661',
  measurementId: 'G-PD1VLJ5XRJ'
};

const ENVIRONMENT = process.env.NODE_ENV === 'production' ? '' : 'STAGING_';

export const FLAG_PHOENIX_SUMUP_REGISTER = ENVIRONMENT + 'PHOENIX_SUMUP_REGISTER';

export const FLAG_PHOENIX_SUMUP_REGISTER_VALIDSEND = ENVIRONMENT + 'PHOENIX_SUMUP_REGISTER_VALIDSEND';